





























const EntwicklungDemoSection = ()=>import('@/components/contents/ShopEntwicklung/EntwicklungDemoSection.vue');
const EntwicklungEcomerceSection = ()=>import('@/components/contents/ShopEntwicklung/EntwicklungEcomerceSection.vue');
const EntwicklungLeistungenSection = ()=>import('@/components/contents/ShopEntwicklung/EntwicklungLeistungenSection.vue');
const EntwicklungUnsereVorgehensweiseSection = ()=>import('@/components/contents/ShopEntwicklung/EntwicklungUnsereVorgehensweiseSection.vue');
const ContentColumnsService = ()=>import('@/components/elements/ContentColumnsService.vue');
const ContentColumnsServices = ()=>import('@/components/elements/ContentColumnsServices.vue');
const ContentColumnsTextImage = ()=>import('@/components/elements/ContentColumnsTextImage.vue');
const ContentSection = ()=>import('@/components/elements/ContentSection.vue');
const ContentShowCaseItem = ()=>import('@/components/elements/ContentShowCaseItem.vue');
const ContentThin = ()=>import('@/components/elements/ContentThin.vue');
const NavRouterLinks = ()=>import('../../elements/NavRouterLinks.vue');
const UndersideHeader = ()=>import('../../elements/UndersideHeader.vue');
const VBtn = ()=>import("vuetify/src/components/VBtn/VBtn");
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {
               EntwicklungEcomerceSection,
               EntwicklungLeistungenSection,
               EntwicklungUnsereVorgehensweiseSection,
               EntwicklungDemoSection,
               UndersideHeader,
               NavRouterLinks,
               VBtn,
               ContentSection,
               ContentThin,
               ContentColumnsService,
               ContentColumnsServices,
               ContentColumnsTextImage,
               ContentShowCaseItem
             }
           })
export default class ShopEntwicklungContent extends Vue {
  @Prop({default: {menuActive: false}}) protected pageData!: object;
  private pageHeaderTitle: string = "Eröffnen Sie Ihren eigenen Online Shop";
  private pageHeaderTags: string = "Digitalisierung leicht gemacht";
  private pageHeaderSubLine: string = "Sie wollen Ihr Business auf den Onlinehandel erweitern?<br><br> Wir erleichtern Ihnen den Einstieg!\n" +
    "    Mit unserem Standard get-a-shop bieten wir eine unkomplizierte und erschwingliche E-Commerce Lösung. " +
    "Je nach Projektanforderung halten wir von der Standard- bis hin zur Individuallösung das Passende für Ihr Geschäft bereit.";
}
