








import BaseSite from '@/views/BaseSite.vue';
import Page from '@/components/elements/Page.vue';
import ShopEntwicklungContent from '@/components/contents/ShopEntwicklung/ShopEntwicklungContent.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
             components: {
               Page,ShopEntwicklungContent
             },
           })
export default class SiteShopEntwicklung extends BaseSite {
  private entwicklungObject: object = {menuActive:false};

}
