import { render, staticRenderFns } from "./ShopEntwicklungContent.vue?vue&type=template&id=2e877aca&scoped=true&"
import script from "./ShopEntwicklungContent.vue?vue&type=script&lang=ts&"
export * from "./ShopEntwicklungContent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e877aca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
installComponents(component, {VContainer})
